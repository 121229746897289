import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBanjo, faDrum, faDrumSteelpan, faGuitarElectric, faGuitars, faHandsClapping } from "@fortawesome/pro-regular-svg-icons";
import { Drum, Hand, CircleGauge, Guitar, KeyboardMusic } from 'lucide-react';
import { IDrumPad } from "../components/DrumPad";

const generateAudioElement = (audioUrl: string): HTMLAudioElement => {
    return new Audio(audioUrl)
}

const DrumPads: IDrumPad[] = [
        {
            id: "kick",
            keyStroke: "q",
            icon: (<Drum />),
            audioElement: generateAudioElement("https://s3.amazonaws.com/freecodecamp/drums/RP4_KICK_1.mp3")
        },
        {
            id: "clap",
            keyStroke: "w",
            icon: (<Hand />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Heater-6.mp3")
        },
        {
            id: "openHiHat",
            keyStroke: "e",
            icon: (<CircleGauge />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Dsc_Oh.mp3")
        },
        {
            id: "closedHiHat",
            keyStroke: "a",
            icon: (<CircleGauge />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Cev_H2.mp3")
        },
        {
            id: "openHiHatt",
            keyStroke: "s",
            icon: (<CircleGauge />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Dsc_Oh.mp3")
        },
        {
            id: "heaterOne",
            keyStroke: "d",
            icon: (<Guitar />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Heater-1.mp3")
        },
        {
            id: "heaterTwo",
            keyStroke: "z",
            icon: (<Guitar />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Heater-2.mp3")
        },
        {
            id: "heaterThree",
            keyStroke: "x",
            icon: (<KeyboardMusic />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Heater-3.mp3")
        },
        {
            id: "heaterFour",
            keyStroke: "c",
            icon: (<Guitar />),
            audioElement: generateAudioElement("https://cdn.freecodecamp.org/testable-projects-fcc/audio/Heater-4_1.mp3")
        }
]

export default DrumPads;