import React from 'react';
import Controller from './components/Controller';

function App() {
  return (
    <div className="App min-h-screen flex justify-center">
      <div id="drum-machine" className="w-full">
        <div id="display" className="container mx-auto px-4 text-center mt-8 font-roboto">
          <header>
            <h1 className="text-4xl font-bold">Drum Machine</h1>
            <Controller/>
          </header>
        </div>
      </div>
    </div>
  );
}

export default App;
