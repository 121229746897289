import React from "react"
import DrumPad from "./DrumPad";
import DrumPads from "../config/drumPads";


const Controller = (): JSX.Element => {
	return (
		<div className="container mx-auto pt-8">
			<div className="grid grid-cols-3 grid-rows-3 gap-4">
				{DrumPads.map((drumPad, i) => {
					return (
						<div key={i}>
							<DrumPad 
								{...drumPad}
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default Controller;